import { Title } from "@solidjs/meta";
import { MetaTags } from "~/components/shared/MetaTags";
import Enga0Page from "~/components/Enga/Enga0Page";

export default function () {
  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Construire des biens qui font du bien, c’est concevoir des appartements qui pensent à demain et prennent soin de chacun. Découvez tous les engagements de Cogedim pour faire de nos appartements une source de bien-être, avec un impact positif sur notre santé et sur l’environnement.",
    },
  };

  return (
    <>
      <Title>Cogedim, des biens qui font du bien - Nos engagements</Title>
      <MetaTags tags={[metaDesc]} />
      <Enga0Page />
    </>
  );
}
