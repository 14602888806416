import imgProxy from "~/utils/imgproxy";
import { useMediaOverlayContext } from "~/contexts/MediaOverlayContext";

import IconPlayArrow from "~/img/icons/play_arrow.svg";
import { Show } from "solid-js";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

export default function FirstScreenFront() {
  const settings = useDrupalSettingsContext();
  const [, { setMediaOverlay }] = useMediaOverlayContext();

  return (
    <>
      <section class="full-visual" data-test="first-screen">
        <picture class="visual">
          <source
            media="(min-width: 1367px)"
            srcset={imgProxy(
              "/images/engagements/front-engagement-visual.jpg",
              "size:1920:1080/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 1025px) and (max-width: 1366px)"
            srcset={imgProxy(
              "/images/engagements/front-engagement-visual.jpg",
              "size:1366:768/resizing_type:fill",
            )}
          />
          <source
            media="(min-width: 768px) and (max-width: 1024px)"
            srcset={imgProxy(
              "/images/engagements/front-engagement-visual.jpg",
              "size:1024:576/resizing_type:fill",
            )}
          />
          <source
            media="(max-width: 767px)"
            srcset={imgProxy(
              "/images/engagements/front-engagement-visual-mobile.jpg",
              "size:750:1334/resizing_type:fill",
            )}
          />
          <img
            fetchpriority="high"
            src={imgProxy(
              "/images/engagements/front-engagement-visual-mobile.jpg",
              `size:375:667/resizing_type:fill`,
            )}
            alt=""
            height="667"
            width="375"
            loading="lazy"
          />
        </picture>
        <h1>
          <span>Pour vous,</span> <span>nous construisons</span>{" "}
          <span>
            des biens qui font du <em>bien</em>.
          </span>
        </h1>
        <Show when={settings.enga_video_main}>
          <button
            class="btn btn-icon"
            onClick={() => {
              setMediaOverlay(
                "embed",
                `<iframe height="100%" width="100%" src="${settings.enga_video_main}" allowfullscreen />`,
              );
            }}
          >
            <i aria-hidden="true" class="cog-icon">
              <IconPlayArrow />
            </i>
          </button>
        </Show>
      </section>
    </>
  );
}
